<script lang="ts">
	import { page } from '$app/stores';
	import { getContext, onMount } from 'svelte';
	import { clickOutside } from '$lib/actions/clickOutside';
	import type { LangCode } from '$params/langCode';
	import { defaultLang, displayLanguages, isSupportedLang, type LANGUAGE } from '$lib/constants/locale';
	let selectedLang: LangCode | '' = defaultLang;
	let isMenuOpen = false;
	const handleLangSelect = (lang: LangCode) => {
		if (selectedLang === lang) {
			return;
		}
		selectedLang = lang;
		let pathname = $page?.url?.pathname || '';
		pathname = decodeURI(pathname);
		const hash = $page?.url?.hash || '';
		const path = pathname.split('/');
		let currentLang: string = defaultLang;
		if(isSupportedLang(path[1])) {
			currentLang = path[1];
		}
		const defaultPath = `/${selectedLang}`;
		let newPath = '';
		let updatedPath = pathname.trim();
		if (updatedPath.endsWith('/')) {
			updatedPath = updatedPath.slice(0, -1);
		}
		if (localizedUrls[currentLang]) {
			const index = localizedUrls[currentLang].indexOf(updatedPath);
			if (index > -1) {
				newPath = localizedUrls[selectedLang || defaultLang][index];
			}
		}
		let hashPath = newPath;
		if (hash && newPath) {
			hashPath = `${newPath}${hash}`;
		}
		window.location.href = hashPath || defaultPath;
	};
	
	const lang = getContext('lang') as LANGUAGE;
	const localizedUrls = getContext('localizedUrls') as any;

	const saveLang = () => {
		if (typeof window !== 'undefined') localStorage.setItem('lang', lang);
	};

	$: {
		lang && saveLang();
	}

	$: {
		if (lang) selectedLang = lang;
	}
</script>

<div class="min-h-9.25 flex-center bg-grey-100 dark:bg-grey-800 relative w-10">
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div
		class="flex-col-center
		min-h-5 rounded-12px
		z-999 absolute
		left-0 top-0 cursor-pointer px-2 py-2
		"
		class:bg-grey-100={isMenuOpen}
		class:dark:bg-grey-800={isMenuOpen}
		class:ring-2={isMenuOpen}
		class:ring-purple={isMenuOpen}
		use:clickOutside={{ enabled: isMenuOpen, callback: () => (isMenuOpen = false) }}
	>
		{#each Object.entries(displayLanguages) as [code, langText]}
			<div
				class:hidden={!isMenuOpen && selectedLang !== code}
				class:order-1={selectedLang !== code}
				class=" dark:bg-grey-800 rubik-regular-base text-grey-800 dark:text-white"
				on:click|stopPropagation={(e) => {
					setTimeout(() => {
						isMenuOpen = !isMenuOpen;
					}, 100);
					//@ts-ignore
					if (isMenuOpen) handleLangSelect(code);
				}}
			>
				{isMenuOpen ? langText : code?.toUpperCase()}
			</div>
		{/each}
	</div>
</div>
